import React from "react";

function MyFilePage() {
  return (
    <div>
      <pre>
        {JSON.stringify(
          {
            apps: [{ clientID: "9534c6d7f425569b245af6b862cdf01d" }],
          },
          null,
          2
        )}
      </pre>
    </div>
  );
}

export default MyFilePage;
